/** @jsx jsx */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { jsx, Container, Button, Box } from 'theme-ui';
import shortid from 'shortid';

const Pagination = ({ data }) => {
  const {
    previousPagePath,
    nextPagePath,
    numberOfPages,
    paginationPath,
  } = data;

  if (numberOfPages > 1) {
    let pages = [];
    for (let i = 0; i < numberOfPages; i++) {
      pages.push({
        label: i + 1,
        linkURL: i === 0 ? paginationPath : `${paginationPath}/${i + 1}`,
      });
    }

    return (
      <Container as="section" variant="content" my={5}>
        <Box sx={{ textAlign: 'center' }}>
          {previousPagePath ? (
            <Button mr={3} as={Link} variant="category" to={previousPagePath}>
              Previous
            </Button>
          ) : null}
          {pages.map((page) => (
            <Link
              key={shortid.generate()}
              to={page.linkURL}
              sx={{
                px: 3,
                fontWeight: 'bold',
                color: 'grey',
                '&.active, &:hover': {
                  color: 'accent',
                },
              }}
              activeClassName="active"
            >
              {page.label}
            </Link>
          ))}
          {nextPagePath ? (
            <Button ml={3} as={Link} variant="category" to={nextPagePath}>
              Next
            </Button>
          ) : null}
        </Box>
      </Container>
    );
  }

  return null;
};

Pagination.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Pagination;
